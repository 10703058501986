import React from "react";

const VISAIcon = () => {
  return (
    <svg
      className='w-[40px] h-[26px]'
      viewBox='0 0 56 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2507_307811)'>
        <g clipPath='url(#clip1_2507_307811)'>
          <path
            d='M52.3434 0.921875H48.4864C48.084 0.924925 47.6913 1.0588 47.3577 1.30673C47.024 1.55466 46.7641 1.90561 46.6106 2.3156L39.8135 20.2482H44.503L45.4514 17.3563H51.258L51.8059 20.2482H56.0212L52.3434 0.921875ZM46.7581 13.419L49.1398 6.14836L50.5097 13.419H46.7581Z'
            fill='#1A1F71'
          />
          <path
            d='M23.2049 0.898438L19.4639 20.248H23.9848L27.7258 0.898438H23.2049Z'
            fill='#1A1F71'
          />
          <path
            d='M16.5027 0.921815L11.8343 14.0693L9.92691 2.87304C9.8507 2.31914 9.59541 1.81426 9.20811 1.45142C8.8208 1.08859 8.32756 0.892253 7.81927 0.898586H0.126385L0.0209961 1.45608C1.55539 1.80418 3.04535 2.35805 4.45759 3.10532C4.7478 3.26842 4.99631 3.50908 5.1804 3.8053C5.36449 4.10151 5.47828 4.44385 5.51141 4.80103L9.126 20.2482H13.942L21.3187 0.898586L16.5027 0.921815Z'
            fill='#1A1F71'
          />
          <path
            d='M33.659 6.26507C33.659 5.5682 34.2702 4.82488 35.5769 4.63905C37.1103 4.48039 38.6541 4.78136 40.0451 5.51013L40.8355 1.39863C39.4863 0.845868 38.0595 0.558881 36.6202 0.550781C32.152 0.550781 29.0011 3.17564 28.98 6.9387C28.98 9.72616 31.2246 11.2825 32.9318 12.2116C34.639 13.1408 35.2924 13.768 35.2819 14.6158C35.2819 15.905 33.8803 16.4857 32.5735 16.509C30.9545 16.5426 29.3545 16.1188 27.9262 15.2778L27.1147 19.5287C28.7173 20.214 30.4225 20.5607 32.1415 20.5508C36.8942 20.5508 40.003 17.9492 40.024 13.9306C40.024 8.82024 33.6168 8.54149 33.659 6.26507Z'
            fill='#1A1F71'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2507_307811'>
          <rect width='56' height='20' fill='white' transform='translate(0 0.550781)' />
        </clipPath>
        <clipPath id='clip1_2507_307811'>
          <rect width='56' height='20' fill='white' transform='translate(0 0.550781)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VISAIcon;
