import { subYears } from "date-fns";
import { DeliveryOption, DeliveryOptionType, Prescription } from "../api/generated";
// import { unzerAllowedCountries } from "../hooks/useUnzer";
import environment from "../config/environment";
import * as yup from "./yup";
import i18n from "../i18n/i18n";
import { TFunction } from "i18next";
import { Checkout as CheckoutType } from "../api/generated";
export const PhoneNumberRegex = /^\+[0-9]{1,15}$/;
export const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PrescriptionRegex = /^[0-9]{9}$/;
export const AutomaticPrescriptionRegex = /^[0-9a-fA-F]{24}$/;

export enum PaymentTypeEnum {
  "card" = "card",
  "sofort" = "sofort",
  "applepay" = "applepay",
  "Invoice" = "Invoice",
  "Prepayment" = "Prepayment",
  "Cash" = "Cash",
  "pay-by-link" = "pay-by-link",
  "paylater-invoice" = "paylater-invoice",
  ForcedPrepayment = "ForcedPrepayment",
  RequestedTransfer = "RequestedTransfer",
}

export type PaymentType =
  | "card"
  | "sofort"
  | "applepay"
  | "Invoice"
  | "Prepayment"
  | "Cash"
  | "pay-by-link"
  | "ForcedPrepayment"
  | "paylater-invoice"
  | "RequestedTransfer"
  | undefined;

export const paymentTypeValue = [
  PaymentTypeEnum.applepay,
  PaymentTypeEnum.card,
  PaymentTypeEnum.Invoice,
  PaymentTypeEnum.Prepayment,
  PaymentTypeEnum.sofort,
  PaymentTypeEnum["pay-by-link"],
  PaymentTypeEnum["paylater-invoice"],
  PaymentTypeEnum.Cash,
  PaymentTypeEnum.ForcedPrepayment,
  PaymentTypeEnum.RequestedTransfer,
];
/**
 */
export type Checkout =
  | "billingFirstName"
  | "billingLastName"
  | "billingAddress"
  | "billingHouseNumber"
  | "billingPhoneNumber"
  | "billingCompany"
  | "billingNotes"
  | "paymentId"
  | "billingPostalCode"
  | "billingCountry"
  | "billingCity"
  | "deliveryFirstName"
  | "deliveryCity"
  | "deliveryLastName"
  | "insurance"
  | "deliveryAddress"
  | "deliveryHouseNumber"
  | "deliveryPhoneNumber"
  | "deliveryCompany"
  | "deliveryNotes"
  | "deliveryPostalCode"
  | "deliveryCountry";
export type CheckoutBoolean =
  | "algeaPatient"
  | "haveCover"
  | "saveCardForNextTime"
  | "doNotUseBillingAddress";
export type CheckoutArray = "receipts" | "covers" | "prescriptions";
export type CheckoutDate = "prescriptionDate" | "billingBirthDay" | "deliveryBirthDay";
export type CheckoutDeliveryType = "typeOfDelivery" | "pickUpOrDelivery" | "currentDeliveryType";
export type CheckoutPaymentType = "paymentType";
export type CheckoutPaymentFields =
  | "cardFirstName"
  | "cardLastName"
  | "cardCreditNumber"
  | "cardValidUntil"
  | "cardCVC";
export type DeliveryType = "selectedDelivery";

/**
 */
export type CheckoutProps = {
  [key in Checkout]?: string;
};
export type CheckoutBooleanProps = {
  [key in CheckoutBoolean]?: boolean;
};
export type CheckoutArrayProps = {
  [key in CheckoutArray]?: any[];
};
export type CheckoutDateProps = {
  [key in CheckoutDate]?: Date;
};
export type CheckoutDeliveryTypeProps = {
  [key in CheckoutDeliveryType]?: DeliveryOptionType | "delivery";
};

export type CheckoutPaymentTypeProps = {
  [key in CheckoutPaymentType]?: PaymentType;
};

export type CheckoutPaymentFieldProps = {
  card?: {
    [key in CheckoutPaymentFields]?: string;
  };
};

export type DeliveryTypeProps = {
  [key in DeliveryType]?: DeliveryOption;
};

export type AllCheckoutType = CheckoutProps &
  CheckoutBooleanProps &
  CheckoutArrayProps &
  CheckoutDateProps &
  CheckoutDeliveryTypeProps &
  CheckoutPaymentFieldProps &
  DeliveryTypeProps &
  CheckoutPaymentTypeProps;

/**
 */
export const CheckoutDefaultValues: AllCheckoutType = {
  receipts: [],
  covers: [],
  doNotUseBillingAddress: true,
  paymentId: "",
  billingCountry: environment.country,
  deliveryCountry: environment.country,
};

const deliveryCountryResolution = yup
  .string()
  .when(
    ["pickUpOrDelivery", "paymentType", "doNotUseBillingAddress"],
    (value: PaymentType[] | DeliveryOptionType[] | boolean[], schema) => {
      // Where value[0] is PaymentType, value[1] is DeliveryOptionType and value[2] is boolean
      if (value?.[2] === true) return schema.notRequired();

      if ((value?.[0] ?? "") === DeliveryOptionType.Pickup) {
        return schema.notRequired();
      }

      // if ((value?.[1] ?? "") === "Invoice") {
      //   return schema.oneOf(unzerAllowedCountries).required();
      // }

      return schema.min(2).required();
    },
  );
const deliveryResolution = yup
  .string()
  .when(["pickUpOrDelivery", "doNotUseBillingAddress"], (value: any, schema) => {
    // Where value[0] is DeliveryOptionType and value[1] is boolean

    if (value?.[1] === true) return schema.notRequired();

    return (value?.[0] ?? "") === DeliveryOptionType.Pickup
      ? schema.notRequired()
      : schema.min(1).required();
  });
const deliveryResolutionPhoneNumber = yup.string().notRequired();
const deliveryCompanyResolution = yup.string().notRequired();
const billingCompanyResolution = yup.string().notRequired();
const deliveryNotesResolution = yup.string().notRequired();
const billingNotesResolution = yup.string().notRequired();

const deliveryDateResolution = yup
  .string()
  .nullable()
  .notRequired()
  .typeError("This is required")
  .when(["pickUpOrDelivery", "doNotUseBillingAddress"], (value: any, schema) => {
    if (value?.[1] === true) return schema.nullable().notRequired();

    return (value?.[0] ?? "") === DeliveryOptionType.Pickup
      ? schema.nullable().notRequired()
      : yup
          .date()
          .typeError(i18n.t("This is required") as string)
          .max(subYears(new Date(), environment.minChildrenAge))
          .typeError(i18n.t("This is required") as string)
          .required();
  });

const billingResolution = yup.string().min(1).required();
const billingPhoneNumberResolution = yup.string().notRequired();
const billingCountryResolution = yup
  .string()
  .when("paymentType", (value: PaymentType[], schema) => {
    return schema.required();
  });
const billingDateResolution = yup
  .date()
  .typeError(i18n.t("This is required") as "")
  .required();

// function validateAtLeastOnePrescription(array) {
//   return array.some((item) => item.prescription_date && item.prescription_number);
// }

const dateSchema = yup.object().shape({
  name: yup.string().required(),
  // label: yup.string().required(),
  value: yup.string(),
  // .typeError(i18n.t("This is required") as "")
  // .min(subYears(new Date(), 4))
  // .max(new Date())
  // .required(),
  type: yup.string().required(),
});

const numberSchema = yup.object().shape({
  name: yup.string(),
  // label: yup.string().required(),
  value: yup.string(),
  // .required(),
  type: yup.string(),
});

const prescriptionsSchema = yup.array().of(
  yup.object().shape({
    id: yup.string(),
    checkoutId: yup.string(),
    prescriptionData: yup.object().shape({
      // prescriptionFilePath: yup.string().url(),
      // coverageFilePath: yup.string().nullable().url(),
      isAlgeaCare: yup.boolean().nullable(),
      // prescription: yup.string().nullable(),
      metaFields: yup.array().of(
        yup.lazy((value, options) => {
          if (value && value.name === "prescription_date") {
            return dateSchema;
          }
          // else if (value && value.name === "prescription_number") {
          //   return numberSchema;
          // }
          return yup.object().shape({});
        }),
      ),
    }),
  }),
);

const isPrescriptionValid = (prescription: any, isAutomaticOrder: boolean) => {
  let isDateValid = false;
  let isPrescriptionFieldValid = false;

  prescription.prescriptionData.metaFields.forEach((field) => {
    // if (field.name === "prescription_number") {
    //   const numberSchema = yup
    //     .string()
    //     .matches(
    //       isAutomaticOrder ? AutomaticPrescriptionRegex : PrescriptionRegex,
    //       isAutomaticOrder ? "This must be 24 numbers and letters" : "This must be 9 numbers",
    //     )
    //     .required()
    //     .when("value", {
    //       is: () => field.value && field.value.length,
    //       then: (schema) =>
    //         schema
    //           .matches(
    //             isAutomaticOrder ? AutomaticPrescriptionRegex : PrescriptionRegex,
    //             isAutomaticOrder ? "This must be 24 numbers and letters" : "This must be 9 numbers",
    //           )
    //           .required(),
    //     });

    //   isNumberValid = numberSchema.isValidSync(field.value);
    // }
    if (field.name === "prescription_date") {
      const fourYearsAgo = new Date();
      fourYearsAgo.setFullYear(fourYearsAgo.getFullYear() - 4);

      const dateSchema = yup
        .string()
        .required()
        .test("valid-date", "Date must be between 4 years ago and today", (value) => {
          const fieldValueDate = new Date(field.value);
          return (
            !isNaN(fieldValueDate.getTime()) &&
            fieldValueDate >= fourYearsAgo &&
            fieldValueDate <= new Date()
          );
        });

      isDateValid = dateSchema.isValidSync(field.value);
    }
  });
  const prescriptionFieldSchema = yup.array().min(1).required();
  isPrescriptionFieldValid = prescriptionFieldSchema.isValidSync(
    prescription?.prescriptionData?.prescription,
  );

  return isDateValid && isPrescriptionFieldValid;
};
const validPrescriptionMessage = i18n.t("At least one prescription must be valid");

export const isAutomaticOrder = (orderNumber: string | undefined) => {
  return orderNumber?.startsWith("A-");
};
export const CheckoutSchema = (
  t: TFunction<"translation", undefined, "translation">,
  isAutomaticOrder = false,
) => {
  yup.setYupLocale(t);

  return yup
    .object()
    .shape({
      algeaPatient: yup.boolean().required(),
      // receipts: yup.array().min(1).required(),
      // prescriptionNumber: yup
      //   .string()
      //   .matches(PrescriptionRegex, i18n.t("This must be 9 numbers") as "")
      //   .required(),
      // prescriptionDate: yup
      //   .date()
      //   .typeError(i18n.t("This is required") as "")
      //   .min(subYears(new Date(), 4))
      //   .max(new Date())
      //   .required(),
      //  prescriptions: yup
      // .array()
      // .of(prescriptionSchema)
      // .test('atLeastOneValid', 'At least one valid prescription is required', validateAtLeastOnePrescription),
      prescriptions: prescriptionsSchema.test(
        "at-least-one-valid",
        validPrescriptionMessage,
        function (value) {
          return value?.some((prescription: any) =>
            isPrescriptionValid(prescription, isAutomaticOrder),
          );
        },
      ),
      haveCover: yup.boolean().required(),
      billingFirstName: billingResolution,
      billingLastName: billingResolution,
      billingAddress: billingResolution,
      billingHouseNumber: billingResolution,
      billingPhoneNumber: billingPhoneNumberResolution,
      billingPostalCode: billingResolution,
      billingBirthDay: billingDateResolution.max(subYears(new Date(), environment.minChildrenAge)),
      billingCountry: billingCountryResolution,
      billingCity: billingResolution,
      billingCompany: billingCompanyResolution,
      billingNotes: billingNotesResolution,
      deliveryCompany: deliveryCompanyResolution,
      deliveryNotes: deliveryNotesResolution,
      deliveryFirstName: deliveryResolution,
      deliveryLastName: deliveryResolution,
      deliveryBirthDay: deliveryDateResolution,
      deliveryAddress: deliveryResolution,
      deliveryHouseNumber: deliveryResolution,
      deliveryPhoneNumber: deliveryResolutionPhoneNumber,
      deliveryCity: deliveryResolution,
      deliveryPostalCode: deliveryResolution,
      deliveryCountry: deliveryCountryResolution,
      paymentId: yup.string().notRequired(),
      doNotUseBillingAddress: yup.boolean().required(),
      typeOfDelivery: yup.string().when("pickUpOrDelivery", (value: any, schema) => {
        return value?.[0] !== DeliveryOptionType.Pickup
          ? schema.oneOf([DeliveryOptionType.Dhl, DeliveryOptionType.Go]).required()
          : schema.notRequired();
      }),
      pickUpOrDelivery: yup.string().oneOf([DeliveryOptionType.Pickup, "delivery"]).required(),
      paymentType: yup
        .string()
        .oneOf(paymentTypeValue as PaymentType[])
        .required(),
    })
    .required();
};
