import { useTranslation } from "react-i18next";

const CardInput = () => {
  const { t } = useTranslation();

  return (
    <div className='w-full unzerUI form space-y-4'>
      <div className='w-full'>
        <Label title={t("Card  Holder")} />
        <div id='card-element-id-holder' className='unzerInput'></div>
      </div>

      <div className='flex flex-row items-center gap-4'>
        <div className='!w-[50%]'>
          <Label title={t("Valid Until")} />
          <div id='card-element-id-expiry' className='unzerInput'></div>
        </div>
        <div className='!w-[47%]'>
          <Label title={t("CVC")} />
          <div id='card-element-id-cvc' className='unzerInput'></div>
        </div>
      </div>

      <div className='field w-full py-3'>
        <Label title={t("Credit Card Number")} />
        <div id='card-element-id-number' className='unzerInput'></div>
      </div>
    </div>
  );
};

export default CardInput;

const Label = ({ title }: { title: string }) => {
  return (
    <label
      className={`block text-gray-700 text-[10px] lg:text-xs md:text-xs xl:text-xs font-gellix font-medium mb-2 ml-1`}
    >
      {title}
    </label>
  );
};
