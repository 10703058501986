import { useCallback } from "react";
import { PublicProduct } from "../api/generated";

// usePrice is used to calculate the total price of a product
// it takes into account the quantity and whether the user has insurance
const usePrice = () => {
  const calculatePrice = useCallback(
    (
      product: Pick<
        PublicProduct,
        "startingPrice" | "startingPriceIsInsurance" | "unitValue" | "basePrice"
      >,
      quantity: number,
    ) => {
      const basePrice = product?.basePrice ?? 0;
      const unitValue = product?.unitValue;
      const startingPrice = product?.startingPrice ?? 0;

      if (!product?.startingPrice) {
        return 0;
      }

      if (!quantity) {
        return basePrice + (unitValue / unitValue) * startingPrice;
      }

      return basePrice + (quantity / unitValue) * startingPrice;
    },
    [],
  );

  return { calculatePrice };
};

export default usePrice;
