import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import environment from "../config/environment";
import useAuth0 from "./useAuth0";
import useBackendError from "./useBackendError";

/**
 * Shows errors returned from a graphql operation
 *
 * @returns
 */

//TODO: Change to explanation
const useGraphqlError = () => {
  const { t } = useTranslation();
  const backendErrors = useBackendError();
  const { logOut } = useAuth0("webshop");

  return {
    showError: async (e: any) => {
      Sentry.captureException(e);
      const errorValue = e?.response?.errors?.map((i: any) => {
        const foundError = backendErrors.find((x) => {
          return i?.message?.includes(x?.type);
        });

        // Here we include the backend messages as it sometimes contains more information
        const foundError2 = `${foundError?.description ?? ""} \n ${
          i?.extensions?.explanation ?? i?.message ?? t("Something went wrong")
        }`;

        if (i.extensions?.code === "ERROR_UNAUTHORIZED") {
          const host = `${window.location.protocol}//${window.location.host}`;

          if (host.includes(environment.adminBaseUrl) || environment.adminBaseUrl.includes(host)) {
            logOut("admin");
          } else {
            logOut("webshop");
          }
        }

        return foundError2;
      });

      toast.error(errorValue?.join("\n"));
    },
  };
};

export default useGraphqlError;
