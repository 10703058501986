import React from "react";
import FlashIcon from "../icon/FlashIcon";
import { useTranslation } from "react-i18next";

export interface BillingNoticeProps {
  icon?: JSX.Element;
  title?: string | any;
}

const BillingNotice = ({ icon, title }: BillingNoticeProps) => {
  const { t } = useTranslation();
  return (
    <div className='bg-warning-alpha text-xs flex flex-row rounded-md py-2 px-2 text-warning'>
      {icon ?? <FlashIcon />}
      <span className='ml-2 font-normal'>
        {title ?? t("Important: fill all information as written on your passport")}
      </span>
    </div>
  );
};

export default BillingNotice;
