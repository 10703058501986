import MessageIcon2 from "../icon/MessageIcon2";

export interface MessageInfoProps {
  title?: React.ReactNode;
  icon?: JSX.Element;
  extraContent?: JSX.Element;
}

const MessageInfo = ({ title, icon, extraContent }: MessageInfoProps) => {
  return (
    <div className='flex flex-row bg-primary-alpha rounded-lg text-xs mb-3  py-2 gap-2 px-3 items-center'>
      {icon ? icon : <MessageIcon2 />}
      <span className='text-[10px] w-[80%]'>
        {extraContent}
        {title}
      </span>
    </div>
  );
};

export default MessageInfo;
