/* eslint-disable no-template-curly-in-string */
import { setLocale } from "yup";
import i18n from "../i18n/i18n";
import Environment from "../config/environment";
import { TFunction } from "i18next";

const { t } = i18n;
if (Environment.forceLanguage) {
  i18n.changeLanguage(Environment.forceLanguage);
}

export const setYupLocale = (t: TFunction) => {
  setLocale({
    mixed: {
      required: t("This field is required") as string,
    },
    string: {
      email: t("Must be a valid email") as string,
      matches: t("Must match {{match}}") as string,
      max: t("Must be at most ${max} characters") as string,
      min: t("Must be at least ${min} characters") as string,
      url: t("Must be a valid URL") as string,
    },
    number: {
      max: t("Must be less than or equal to ${max}") as string,
      min: t("Must be greater than or equal to ${min}") as string,
    },

    date: {
      max: t("Must be before ${max}") as string,
      min: t("Must be after ${min}") as string,
    },
    boolean: {
      isValue: t("Must be checked") as string,
    },
  });
};

setYupLocale(t);
// const urlRegex = /^(?:https?:\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-]+)*(?:\/)?(?:\?[\w%=&-]+)?(?:#[\w-]+)?$/i;

export const URL_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-]+)*(?:\/)?(?:\?[\w%=&-]+)?(?:#[\w-]+)?$/i;

export const PHONE_NUMBER_REGEX = /^\+?\d{1,4}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
export const TWO_DECIMAL_PLACES_REGEX = /^\d+(\.\d{0,2})?$/;
export * from "yup";
