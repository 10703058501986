/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useTranslation } from "react-i18next";
import {
  CheckoutStatus,
  InsuranceType,
  NcmStatus,
  NcmStatusUser,
  PaymentStatus,
  Unit,
} from "../api/generated";
import { useGlobalState } from "@gruene-brise/data-access/state/useGlobalState";
import {
  getDefaultPaymentTypes,
  PaymentButtonProps,
} from "@gruene-brise/common-ui/webshop/CheckoutPaymentInput";

import { useMemo } from "react";

export function useStatusTranslations() {
  const { t } = useTranslation();
  const paymentTypes = getDefaultPaymentTypes({ t });
  const { adminLanguage } = useGlobalState();

  const orderStatusTranslations: { [k in CheckoutStatus]: string } = useMemo(
    () => ({
      [CheckoutStatus.Pending]: t("Pending"),
      [CheckoutStatus.Verification]: t("Verification"),
      [CheckoutStatus.Adjusting]: t("Adjusting"),
      [CheckoutStatus.Cancelled]: t("Cancelled"),
      [CheckoutStatus.Completed]: t("Completed"),
      [CheckoutStatus.Draft]: t("Draft"),
      [CheckoutStatus.Esignature]: t("Esignature"),
      [CheckoutStatus.Processing]: t("Processing"),
      [CheckoutStatus.Created]: t("Created"),
    }),
    [t],
  );

  const deliveryMethodTranslations: { [k: string]: string } = useMemo(
    () => ({
      pickup: t("Pickup"),
      DHL: t("DHL"),
      "GO!": t("GO"),
    }),
    [t],
  );

  const paymentMethodTranslations: { [k: string]: string } = useMemo(() => {
    return (paymentTypes as PaymentButtonProps[]).reduce((acc, curr) => {
      acc[curr.type] = curr.label;
      return acc;
    }, {}) as { [k: string]: string };
  }, [t, paymentTypes, adminLanguage]);

  const paymentStatusTranslations: { [k in PaymentStatus]: string } = useMemo(
    () => ({
      [PaymentStatus.Authorized]: t("Authorized"),
      [PaymentStatus.Paid]: t("Paid"),
      [PaymentStatus.Pending]: t("Pending"),
      [PaymentStatus.Refunded]: t("Refunded"),
      [PaymentStatus.Partial]: t("Partial"),
      [PaymentStatus.Failed]: t("Failed"),
    }),
    [t, adminLanguage],
  );

  const productUnitTranslation: { [k in Unit]: string } = useMemo(
    () => ({
      [Unit.Gram]: t("g"),
      [Unit.Milliliter]: t("ml"),
      [Unit.Piece]: t("pi"),
    }),
    [t, adminLanguage],
  );

  const insuranceTranslation: { [k: string]: string } = useMemo(
    () => ({
      [InsuranceType.Private]: t("Private"),
      [InsuranceType.Public]: t("Public"),
    }),
    [t, adminLanguage],
  );

  const ncmTranslation: { [k: string]: string } = useMemo(
    () => ({
      [NcmStatus.Completed]: t("Completed"),
      [NcmStatus.Created]: t("Created"),
      [NcmStatus.Draft]: t("Draft"),
      [NcmStatus.OrderPlaced]: t("OrderPlaced"),
      [NcmStatus.Rejected]: t("Rejected"),
    }),
    [t, adminLanguage],
  );

  const ncmUserTranslation: { [k: string]: string } = useMemo(
    () => ({
      [NcmStatusUser.Awaiting]: t("Awaiting"),
      [NcmStatusUser.Cancelled]: t("Cancelled"),
      [NcmStatusUser.NotApplicable]: t("NotApplicable"),
      [NcmStatusUser.OrderPlaced]: t("OrderPlaced"),
    }),
    [t, adminLanguage],
  );

  return {
    productUnitTranslation: (unit: Unit) => productUnitTranslation[unit] || unit,
    translateOrderStatus: (status: CheckoutStatus) => orderStatusTranslations[status] || status,
    translateDeliveryMethod: (method: string) => deliveryMethodTranslations[method] || method,
    translateInsuranceType: (type: string) => insuranceTranslation[type] || type,
    translatePaymentMethod: (method: string) => paymentMethodTranslations[method] || method,
    translatePaymentStatus: (status: PaymentStatus) => paymentStatusTranslations[status] || status,
    translateNcmStatus: (status: NcmStatus) => ncmTranslation[status] || status,
    translateNcmUserStatus: (status: NcmStatusUser) => ncmUserTranslation[status] || status,
  };
}
