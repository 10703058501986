import { useCallback } from "react";

export interface useCurrenctFormatterProps {
  currency?: string;
  country?: string;
}

/**
 *
 * UseCurrencyFormatter is a hook that handles displaying numbers and specified currency
 *
 * @param currencyFormatter
 * @returns
 */
const useCurrencyFormatter = (currencyFormatter?: useCurrenctFormatterProps) => {
  return useCallback((price: number, shouldNotDivideBy100?: boolean) => {
    const numberFormat = new Intl.NumberFormat(currencyFormatter?.country ?? "de-DE", {
      style: "currency",
      currency: currencyFormatter?.currency ?? "EUR",
    });
    return shouldNotDivideBy100 ? numberFormat.format(price) : numberFormat.format(price / 100);
  }, []);
};

export const useCurrencyFormatterNumber = (currencyFormatter?: useCurrenctFormatterProps) => {
  return useCallback((price: number) => {
    const numberFormat = new Intl.NumberFormat(currencyFormatter?.country ?? "de-DE");
    return numberFormat.format(price / 100);
  }, []);
};

export default useCurrencyFormatter;
