import MasterCardIcon from "@gruene-brise/common-ui/icon/MasterCardIcon";
import { useUnzerResultProps } from "libs/data-access/src/lib/hooks/useUnzer";
import useAddress from "libs/data-access/src/lib/hooks/useAddress";
import {
  AllCheckoutType,
  PaymentType,
  PaymentTypeEnum,
} from "libs/data-access/src/lib/schema/CheckoutSchemes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AvailablePaymentMethodsQuery,
  DeliveryOptionType,
} from "@gruene-brise/data-access/api/generated";
import { chain, isEmpty } from "lodash";
import { UseFormSetValue } from "react-hook-form";
import VISAIcon from "../icon/VISAIcon";
import CardInput from "../payment/CardInput";
import InvoiceInput from "../payment/InvoiceInput";
import BillingNotice from "./BillingNotice";
import { CheckoutBillingInputProps } from "./CheckoutBillingInput";
import { CheckoutItemProps } from "./CheckoutItem";
import { TFunction } from "i18next";
import { Tooltip } from "@chakra-ui/react";
import { FSWatcher } from "fs";

export interface CheckoutPaymentInputProps extends CheckoutBillingInputProps {
  showOnlySelectedPayment?: boolean;
  canEdit?: boolean;
  hideTitle?: boolean;
  unzer?: useUnzerResultProps;
  useTopBillingNotice?: boolean;
  billingNotice?: string;
  paymentAvailable: AvailablePaymentMethodsQuery;
}

export interface PaymentButtonProps {
  description?: JSX.Element | string;
  onPress?(e?: any, total?: any): void;
  type?: PaymentType;
  label?: string;
  wide?: boolean;
}

export type AllCheckoutPaymentInputProps = CheckoutPaymentInputProps &
  CheckoutItemProps & {
    hidePayment?: PaymentType[];
    includePaymentMethods?: PaymentType[];
    title?: string;
    titleClassName?: string;
    excludePaymentMethods?: PaymentTypeEnum[];
    isDifferentShippingAddress?: boolean;
  };

/**
 * CheckoutPaymentInput is a component that shows all the payment methods available during a checkout.
 * It also shows only a selected button depending on the available props set in
 *
 * @param showOnlySelectedPayment
 * @param hideTitle
 * @param unzer
 * @param canEdit
 * @param CheckoutBillingInputProps
 * @returns JSX.Element
 */
const CheckoutPaymentInput = ({
  showOnlySelectedPayment,
  hideTitle,
  watch,
  setValue,
  errors,
  unzer,
  useTopBillingNotice = false,
  billingNotice,
  checkoutData,
  hidePayment,
  paymentAvailable,
  includePaymentMethods,
  excludePaymentMethods,
  title,
  titleClassName,
  isDifferentShippingAddress,
}: AllCheckoutPaymentInputProps) => {
  const { t } = useTranslation();
  // Find available payment according to the checkoutId
  const paymentAvailability = paymentAvailable?.availablePaymentMethods?.paymentMethods ?? [];

  // Default available payment methods
  const defaultPaymentButtons: Array<PaymentButtonProps> = useMemo(() => {
    return getDefaultPaymentTypes({
      t,
      setValue,
    }).filter((payment) => !excludePaymentMethods?.includes(payment.type as PaymentTypeEnum));
  }, [t, setValue]);

  useEffect(() => {
    if (isDifferentShippingAddress === true && watch?.("paymentType") === "paylater-invoice") {
      setValue?.("paymentType", undefined);
    }
  }, [isDifferentShippingAddress]);

  // Includes apple pay into default payment only if they used safari browser due to Apple web payment restrictions
  //TODO: Revisit Apple Payment
  // if (/^((?!chrome|android).)*safari/i.test(global?.navigator?.userAgent ?? "")) {
  // paymentButtons.push({
  //   description: (
  //     <div className=''>
  //       <img src='/images/apple.png' className='w-[50px]' />
  //     </div>
  //   ),
  //   onPress: async (uzr: useUnzerResultProps, total: any) => {
  //     try {
  //       const paymentId = await uzr?.getApplePayInput(total);
  //       setValue?.("paymentType", "applepay");
  //       setValue?.("paymentId", paymentId);
  //       trigger?.("paymentType");
  //       trigger?.("paymentId");
  //     } catch (e: any) {
  //       toast.error(e);
  //     }
  //   },
  //   type: "applepay",
  // });
  // }

  // If the user selects pickup, add cash as a payment option

  const transferPayments = useMemo(() => {
    return (
      watch?.("paymentType") === PaymentTypeEnum.Prepayment ||
      watch?.("paymentType") === PaymentTypeEnum.Invoice ||
      watch?.("paymentType") === PaymentTypeEnum.RequestedTransfer
    );
  }, [watch?.("paymentType")]);

  const paymentButtons: Array<PaymentButtonProps> = useMemo(() => {
    if (watch?.("pickUpOrDelivery") === DeliveryOptionType.Pickup) {
      if (defaultPaymentButtons.find((i) => i.type === PaymentTypeEnum.Cash))
        return defaultPaymentButtons;

      return [...defaultPaymentButtons, PayByCash(t, setValue!)];
    }

    return defaultPaymentButtons.filter((i) => i.type !== "Cash");
  }, [defaultPaymentButtons, watch?.("pickUpOrDelivery")]);

  // Check the backend if we can see the default available payment methods
  // If they're not included in the backend they won't be added to the payment method processed list
  const paymentFromAvailablePayment = useMemo(() => {
    const temp: PaymentButtonProps[] = [];
    paymentAvailability.forEach((q) => {
      paymentButtons.map((payment) => {
        if (payment.type === q.type) {
          temp.push(payment);
        }
      });
    });

    return temp;
  }, [paymentAvailability, paymentButtons]);

  const paymentButtonsSemiProcessed = useMemo(() => {
    const temp: PaymentButtonProps[] = [];
    paymentFromAvailablePayment.forEach((q) => {
      if (!(hidePayment ?? []).includes(q.type)) {
        temp.push(q);
      }
    });

    return temp;
  }, [paymentFromAvailablePayment, hidePayment]);

  const paymentButtonProcessed = useMemo(() => {
    const temp: PaymentButtonProps[] = paymentButtonsSemiProcessed;

    includePaymentMethods?.map((payment) => {
      paymentButtons.map((i) => {
        if (i.type === payment && !temp.map((q) => q.type).includes(payment)) {
          temp.push(i);
        }
      });
    });

    return temp;
  }, [paymentButtonsSemiProcessed, includePaymentMethods]);

  // We want to select the payment method if there's a previous value
  useEffect(() => {
    if (showOnlySelectedPayment) return;
    if (!watch?.("paymentType") || watch?.("paymentType") === "applepay") return;

    if (!isEmpty(watch("paymentType"))) return;
    paymentButtonProcessed.find((i) => i.type === watch?.("paymentType"))?.onPress?.();
  }, [paymentButtonProcessed, watch?.("paymentType")]);

  const selectionClass = (i: PaymentType) => {
    return i === watch?.("paymentType") ? "border-[1px] border-primary" : "";
  };

  // If showOnlySelectedPayment is true, it only shows that payment method
  const paymentTypeProcessed = useMemo(() => {
    return showOnlySelectedPayment
      ? paymentButtonProcessed.filter((i) => i.type === watch?.("paymentType"))
      : paymentButtonProcessed;
  }, [showOnlySelectedPayment, watch?.("paymentType"), paymentButtonProcessed]);

  // This is to split the payment buttons based on the design
  const groupByButtonTypes = useMemo(() => {
    return chain(paymentTypeProcessed)
      .groupBy("wide")
      .map((value, key) => ({ key, value }))
      .value();
  }, [paymentTypeProcessed]);

  const billingNoticeComp = useMemo(() => {
    return (
      <>
        {watch?.("paymentType") === PaymentTypeEnum.Prepayment && (
          <BillingNotice
            title={
              billingNotice ??
              t("Important: Your order will only be processed after receipt of payment")
            }
          />
        )}
      </>
    );
  }, [billingNotice, watch?.("paymentType")]);

  return (
    <div className='w-full'>
      {!hideTitle && (
        <div className={`${titleClassName} font-bold mb-4`}>{title ?? t("Payment")}</div>
      )}

      {useTopBillingNotice && <div className='w-full my-4'>{billingNoticeComp}</div>}

      <div className='flex flex-col w-full gap-2'>
        {groupByButtonTypes.map(({ value, key }) => {
          const isWide = key === "true";

          return (
            <div
              className={`w-full ${
                isWide ? "grid-cols-2" : `grid-cols-${groupByButtonTypes.length}`
              } grid gap-2`}
              key={key}
            >
              {value.map((i) => {
                return (
                  <div
                    className={`bg-primary-alpha cursor-pointer px-5 rounded-md ${
                      isWide ? "py-3" : "py-5"
                    } ${
                      isDifferentShippingAddress && i.type === "paylater-invoice"
                        ? "bg-primary-25 cursor-not-allowed"
                        : ""
                    } w-3/3 items-center justify-center flex flex-col text-sm font-semibold ${selectionClass(
                      i.type,
                    )}`}
                    onClick={() => {
                      if (showOnlySelectedPayment) return;
                      if (isDifferentShippingAddress && i.type === "paylater-invoice") return;
                      i?.onPress?.(unzer, checkoutData?.total ?? 0);
                    }}
                  >
                    {isDifferentShippingAddress && i.type === "paylater-invoice" ? (
                      <Tooltip
                        label={t(
                          "This payment can not be used because the shipment address is different from the billing address",
                        )}
                        className='cursor-pointer !rounded-xl w-[150px] mr-2 !p-3'
                        placement='left'
                        hasArrow
                      >
                        {i.description}
                      </Tooltip>
                    ) : (
                      i.description
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      {errors?.paymentType?.message && (
        <div className='text-fail text-xs w-full mt-1 font-medium'>
          {t("Missing information. Please select an option.")}
        </div>
      )}

      {transferPayments && (
        <div className='w-full text-xs font-gellix flex flex-col gap-2 mt-4'>
          {watch?.("paymentType") === PaymentTypeEnum.Prepayment && (
            <div className='font-semibold'>
              {t("Please transfer the amount within 3 days to the following account:")}
            </div>
          )}

          {watch?.("paymentType") === PaymentTypeEnum.RequestedTransfer && (
            <div className='font-semibold'>
              {checkoutData?.pharmacy?.name}{" "}
              {t("Pharmacy in {{location}}", {
                location: useAddress({ address: checkoutData?.pharmacy?.address ?? undefined }),
              })}
            </div>
          )}

          <div className='font-semibold flex'>
            <div className='w-[150px]'>{t("Recipient")}:</div>
            {checkoutData?.pharmacy?.bankInfo?.owner}
          </div>
          <div className='flex'>
            <div className='w-[150px]'>{t("Bank Name")}:</div>
            {checkoutData?.pharmacy?.bankInfo?.bank}
          </div>
          <div className='flex'>
            <div className='w-[150px]'>{t("Account")}:</div>
            {checkoutData?.pharmacy?.bankInfo?.iban}
          </div>
          <div className='flex'>
            <div className='w-[150px]'>BIC:</div> {checkoutData?.pharmacy?.bankInfo?.bic}
          </div>
        </div>
      )}

      {!useTopBillingNotice && <div className='w-full my-4'>{billingNoticeComp}</div>}

      <div
        className={
          watch?.("paymentType") === "card" && !showOnlySelectedPayment ? "block w-full" : "hidden"
        }
      >
        <CardInput />
      </div>

      <div
        className={
          watch?.("paymentType") === "paylater-invoice" && !showOnlySelectedPayment
            ? "block"
            : "hidden"
        }
      >
        <InvoiceInput />
      </div>
    </div>
  );
};

export default CheckoutPaymentInput;

export interface useDefaultPaymentProps {
  setValue?: UseFormSetValue<AllCheckoutType>;
  t: TFunction<"translation", undefined, "translation">;
}

export const PayByCash = (t: TFunction, setValue: UseFormSetValue<AllCheckoutType>) => {
  return {
    description: t("Pay by Cash")!,
    onPress: () => {
      setValue?.("paymentType", "Cash", { shouldValidate: true });
    },
    type: "Cash",
    label: t("Cash")!,
  } as PaymentButtonProps;
};

export const getDefaultPaymentTypes = ({ t, setValue }: useDefaultPaymentProps) => {
  const temp = [
    {
      description: (
        <div className='flex flex-row gap-3'>
          <MasterCardIcon />
          <VISAIcon />
        </div>
      ),
      label: t("Card"),
      onPress: (uzr: useUnzerResultProps) => {
        // Select card payment and perform unzer cardPaymentInput operation
        setValue?.("paymentType", PaymentTypeEnum.card, { shouldValidate: true });
        uzr?.cardPaymentInput();
      },
      type: PaymentTypeEnum.card,
    },
    // {
    //   description: <img src='/images/sofort.png' className='h-[50px] resize-y' />,
    //   onPress: () => {
    //     // Select sofort payment
    //     setValue?.("paymentType", PaymentTypeEnum.sofort, { shouldValidate: true });
    //   },
    //   label: t("Sofort"),
    //   type: PaymentTypeEnum.sofort,
    // },
    {
      description: t("Pay Advance")!,
      onPress: () => {
        // Select payment method
        setValue?.("paymentType", PaymentTypeEnum.Prepayment, { shouldValidate: true });
      },
      type: PaymentTypeEnum.Prepayment,
      label: t("Pay Advance")!,
      // wide: true,
    },
    {
      description: t("Pay Later")!,
      onPress: async () => {
        // perform invoicePaymentInput and if it is successful, set paylater invoice as selected payment method
        try {
          setValue?.("paymentType", PaymentTypeEnum.Invoice, { shouldValidate: true });
        } catch {}
      },
      type: PaymentTypeEnum.Invoice,
      label: t("Pay Later")!,
      wide: true,
    },
    {
      description: t("Pay Later Invoice")!,
      onPress: async (uzr: useUnzerResultProps) => {
        try {
          await uzr?.invoicePaymentInputWithoutCustomer();
          setValue?.("paymentType", PaymentTypeEnum["paylater-invoice"], { shouldValidate: true });
        } catch {}
      },
      type: PaymentTypeEnum["paylater-invoice"],
      label: t("Pay Later Invoice")!,
      wide: true,
    },
    {
      description: t("Pay Link")!,
      onPress: () => {
        // Select payment method
        setValue?.("paymentType", PaymentTypeEnum["pay-by-link"], { shouldValidate: true });
      },
      type: PaymentTypeEnum["pay-by-link"],
      label: t("Pay Link")!,
      wide: true,
    },
    {
      description: t("Mandatory Payment")!,
      onPress: () => {
        // Select payment method
        setValue?.("paymentType", PaymentTypeEnum.ForcedPrepayment, { shouldValidate: true });
      },
      type: PaymentTypeEnum.ForcedPrepayment,
      label: t("Mandatory Payment")!,
      wide: true,
    },

    {
      description: t("Transfer")!,
      onPress: () => {
        // Select payment method
        setValue?.("paymentType", PaymentTypeEnum.RequestedTransfer, { shouldValidate: true });
      },
      type: PaymentTypeEnum.RequestedTransfer,
      label: t("Transfer")!,
    },
    PayByCash(t, setValue!),
  ] as PaymentButtonProps[];

  return [...temp];
};
