import React from "react";

const MasterCardIcon = () => {
  return (
    <svg
      className='w-[25px] h-[27px]'
      viewBox='0 0 39 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2507_307810)'>
        <g clipPath='url(#clip1_2507_307810)'>
          <path
            d='M16.7808 28.9359C16.4404 28.9359 16.2743 28.7382 16.2743 28.414V26.9119H17.47V26.398H16.2743V25.4492H15.6184V26.398H14.9375V26.9514H15.6184V28.4299C15.6184 29.1414 15.8924 29.5684 16.7144 29.5684C17.026 29.5637 17.3314 29.4849 17.6028 29.3391L17.4035 28.7856C17.216 28.8929 16.9994 28.9452 16.7808 28.9359Z'
            fill='#121F0B'
          />
          <path
            d='M10.1546 26.7534C10.0293 26.6011 9.86723 26.4799 9.6822 26.4001C9.49717 26.3203 9.29463 26.2841 9.0918 26.2948C8.63816 26.2948 8.2031 26.4664 7.88232 26.7718C7.56155 27.0773 7.38135 27.4915 7.38135 27.9235C7.38135 28.3554 7.56155 28.7697 7.88232 29.0751C8.2031 29.3806 8.63816 29.5522 9.0918 29.5522C9.29463 29.5628 9.49717 29.5267 9.6822 29.4468C9.86723 29.367 10.0293 29.2458 10.1546 29.0936V29.4889H10.8023V26.3264H10.1546V26.7534ZM9.19144 28.9671C9.04932 28.9725 8.90765 28.9486 8.77612 28.897C8.6446 28.8455 8.52631 28.7675 8.42934 28.6684C8.33238 28.5693 8.25902 28.4515 8.21429 28.3229C8.16955 28.1943 8.1545 28.0581 8.17015 27.9235C8.18625 27.7372 8.25886 27.5594 8.37914 27.4116C8.49942 27.2639 8.66218 27.1526 8.84764 27.0913C9.0331 27.0301 9.23327 27.0214 9.42379 27.0665C9.61432 27.1115 9.787 27.2083 9.92083 27.345C10.0547 27.4818 10.1439 27.6526 10.1776 27.8368C10.2114 28.0209 10.1882 28.2104 10.1109 28.3823C10.0337 28.5541 9.90563 28.7008 9.74239 28.8047C9.57914 28.9085 9.38774 28.9649 9.19144 28.9671Z'
            fill='#121F0B'
          />
          <path
            d='M5.53823 26.2928C5.31126 26.2847 5.08582 26.3312 4.88268 26.4279C4.67953 26.5247 4.50521 26.6685 4.37578 26.8462C4.26305 26.6679 4.10149 26.5222 3.9084 26.4247C3.71532 26.3272 3.498 26.2816 3.27977 26.2928C3.08823 26.2864 2.89798 26.3248 2.7257 26.4048C2.55342 26.4847 2.40439 26.6037 2.29169 26.7513V26.356H1.63574V29.5185H2.29169V27.6922C2.28148 27.5859 2.29594 27.4788 2.33406 27.3785C2.37218 27.2782 2.43302 27.187 2.51225 27.1116C2.59149 27.0361 2.68719 26.9782 2.79257 26.9419C2.89795 26.9056 3.01042 26.8918 3.12201 26.9016C3.60359 26.9016 3.8776 27.1941 3.8776 27.6922V29.4869H4.52524V27.6922C4.51503 27.5859 4.5295 27.4788 4.56762 27.3785C4.60573 27.2782 4.66656 27.187 4.7458 27.1116C4.82504 27.0361 4.92074 26.9782 5.02612 26.9419C5.13149 26.9056 5.24397 26.8918 5.35556 26.9016C5.83714 26.9016 6.11115 27.1941 6.11115 27.6922V29.4869H6.82522V27.5341C6.84156 27.3685 6.81962 27.2016 6.76096 27.045C6.7023 26.8885 6.60833 26.746 6.48567 26.6277C6.363 26.5094 6.21462 26.4181 6.05094 26.3602C5.88726 26.3024 5.71226 26.2794 5.53823 26.2928Z'
            fill='#121F0B'
          />
          <path
            d='M13.2603 27.6379H12.9198C12.6126 27.6379 12.3386 27.5035 12.3386 27.3138C12.3386 27.124 12.6126 26.9185 12.9863 26.9185C13.3452 26.9337 13.6964 27.0227 14.0159 27.1794L14.3563 26.6892C13.956 26.4604 13.4943 26.3477 13.0278 26.365C12.1975 26.365 11.691 26.7208 11.691 27.3454C11.691 27.97 12.0646 28.136 12.787 28.2546H13.1274C13.5011 28.3178 13.7419 28.4523 13.7419 28.6104C13.7419 28.7685 13.4679 29.0057 12.9115 29.0057C12.5218 29.012 12.1403 28.8983 11.8238 28.6815L11.4751 29.1638C11.8907 29.4264 12.3796 29.5641 12.8783 29.5591C13.8332 29.5591 14.348 29.1322 14.348 28.5471C14.348 27.9621 13.9827 27.6933 13.2603 27.6379Z'
            fill='#121F0B'
          />
          <path
            d='M19.687 26.2934C19.4658 26.2881 19.2458 26.3274 19.0416 26.4088C18.8374 26.4902 18.6536 26.6118 18.5022 26.7656C18.3509 26.9194 18.2354 27.102 18.1634 27.3013C18.0914 27.5006 18.0646 27.7122 18.0845 27.9221C18.0692 28.1357 18.1013 28.35 18.1786 28.551C18.256 28.7521 18.3769 28.9353 18.5336 29.089C18.6902 29.2426 18.879 29.3631 19.0877 29.4426C19.2965 29.5222 19.5205 29.5591 19.7452 29.5508C20.2264 29.5582 20.6956 29.4074 21.0737 29.1239L20.7332 28.6732C20.4582 28.8734 20.1244 28.9867 19.7784 28.9974C19.5387 29.0074 19.3038 28.9319 19.1195 28.7857C18.9352 28.6395 18.8149 28.433 18.782 28.2068H21.2148V27.9458C21.2418 27.7394 21.2225 27.5298 21.1582 27.3309C21.0938 27.132 20.9859 26.9485 20.8415 26.7923C20.6971 26.6361 20.5195 26.5109 20.3205 26.4249C20.1215 26.3389 19.9056 26.2941 19.687 26.2934ZM18.7322 27.6375C18.7567 27.4208 18.8643 27.2204 19.0343 27.0745C19.2044 26.9286 19.425 26.8476 19.6538 26.8469C19.874 26.8469 20.0852 26.9302 20.241 27.0784C20.3967 27.2267 20.4841 27.4278 20.4841 27.6375H18.7322Z'
            fill='#121F0B'
          />
          <path
            d='M36.8245 26.7537C36.6992 26.6014 36.5371 26.4803 36.3521 26.4004C36.1671 26.3206 35.9646 26.2845 35.7617 26.2951C35.3081 26.2951 34.873 26.4667 34.5522 26.7721C34.2315 27.0776 34.0513 27.4918 34.0513 27.9238C34.0513 28.3558 34.2315 28.77 34.5522 29.0754C34.873 29.3809 35.3081 29.5525 35.7617 29.5525C35.9646 29.5631 36.1671 29.527 36.3521 29.4472C36.5371 29.3673 36.6992 29.2462 36.8245 29.0939V29.4892H37.4722V25.125H36.8245V26.7537ZM35.8614 28.9674C35.7192 28.9728 35.5776 28.9489 35.446 28.8973C35.3145 28.8458 35.1962 28.7678 35.0993 28.6687C35.0023 28.5696 34.9289 28.4518 34.8842 28.3232C34.8395 28.1947 34.8244 28.0584 34.8401 27.9238C34.8562 27.7375 34.9288 27.5597 35.0491 27.4119C35.1693 27.2642 35.3321 27.1529 35.5176 27.0917C35.703 27.0304 35.9032 27.0217 36.0937 27.0668C36.2842 27.1118 36.4569 27.2086 36.5907 27.3454C36.7246 27.4821 36.8138 27.653 36.8475 27.8371C36.8813 28.0212 36.8581 28.2107 36.7809 28.3826C36.7036 28.5544 36.5755 28.7012 36.4123 28.805C36.2491 28.9088 36.0577 28.9653 35.8614 28.9674Z'
            fill='#121F0B'
          />
          <path
            d='M22.7092 26.7518V26.3565H22.0532V29.519H22.7092V27.7243C22.7092 27.2025 22.9832 26.8783 23.4232 26.8783C23.5754 26.8824 23.7263 26.9063 23.8716 26.9495L24.0709 26.3565C23.9158 26.32 23.7572 26.2988 23.5976 26.2933C23.4203 26.289 23.245 26.3291 23.0891 26.4095C22.9331 26.49 22.8021 26.608 22.7092 26.7518Z'
            fill='#121F0B'
          />
          <path
            d='M32.5485 26.7518V26.3565H31.8926V29.519H32.5485V27.7243C32.5485 27.2025 32.8225 26.8783 33.2626 26.8783C33.4148 26.8824 33.5656 26.9063 33.711 26.9495L33.9102 26.3565C33.7552 26.32 33.5966 26.2988 33.437 26.2933C33.2597 26.289 33.0843 26.3291 32.9284 26.4095C32.7725 26.49 32.6415 26.608 32.5485 26.7518Z'
            fill='#121F0B'
          />
          <path
            d='M25.9472 26.9092C26.2497 26.9199 26.5406 27.0224 26.7776 27.2017L27.118 26.7194C26.7932 26.4617 26.3775 26.3317 25.9556 26.3557C25.7294 26.3331 25.5008 26.3581 25.2859 26.4289C25.071 26.4997 24.875 26.6145 24.7118 26.7654C24.5487 26.9162 24.4223 27.0993 24.3417 27.3018C24.261 27.5042 24.228 27.7211 24.2451 27.937C24.2382 28.1526 24.2777 28.3674 24.3612 28.5679C24.4447 28.7685 24.5705 28.9507 24.7307 29.1033C24.8909 29.2558 25.0823 29.3756 25.2929 29.4551C25.5035 29.5346 25.7291 29.5722 25.9556 29.5657C26.3764 29.5919 26.792 29.4647 27.118 29.2099L26.7776 28.7197C26.5424 28.902 26.2504 29.0049 25.9472 29.0122C25.6566 29.0122 25.3778 28.9023 25.1722 28.7066C24.9667 28.5108 24.8512 28.2454 24.8512 27.9686C24.8512 27.6918 24.9667 27.4264 25.1722 27.2307C25.3778 27.0349 25.6566 26.925 25.9472 26.925V26.9092Z'
            fill='#121F0B'
          />
          <path
            d='M30.2567 26.7534C30.1313 26.6011 29.9693 26.4799 29.7842 26.4001C29.5992 26.3203 29.3967 26.2841 29.1938 26.2948C28.7402 26.2948 28.3052 26.4664 27.9844 26.7718C27.6636 27.0773 27.4834 27.4915 27.4834 27.9235C27.4834 28.3554 27.6636 28.7697 27.9844 29.0751C28.3052 29.3806 28.7402 29.5522 29.1938 29.5522C29.3967 29.5628 29.5992 29.5267 29.7842 29.4468C29.9693 29.367 30.1313 29.2458 30.2567 29.0936V29.4889H30.9043V26.3264H30.2567V26.7534ZM29.3018 28.9671C29.159 28.9736 29.0164 28.9506 28.8838 28.8996C28.7513 28.8485 28.632 28.7707 28.5341 28.6715C28.4362 28.5723 28.3621 28.454 28.3169 28.3249C28.2717 28.1957 28.2564 28.0588 28.2722 27.9235C28.2883 27.7375 28.3607 27.56 28.4806 27.4124C28.6005 27.2649 28.7628 27.1536 28.9478 27.0921C29.1328 27.0306 29.3326 27.0215 29.5229 27.0659C29.7132 27.1103 29.8859 27.2063 30.0201 27.3423C30.1542 27.4783 30.2441 27.6484 30.2788 27.8321C30.3134 28.0157 30.2914 28.205 30.2154 28.3769C30.1394 28.5489 30.0126 28.6962 29.8504 28.8009C29.6883 28.9057 29.4977 28.9634 29.3018 28.9671Z'
            fill='#121F0B'
          />
          <path
            d='M24.6189 11.8725C24.6312 13.6187 24.2438 15.3465 23.4838 16.9358C22.7239 18.5251 21.6095 19.9376 20.2183 21.0754C22.23 22.4612 24.6518 23.2032 27.1348 23.1942C30.2883 23.1942 33.3126 22.0014 35.5424 19.8782C37.7722 17.755 39.0249 14.8752 39.0249 11.8725C39.0249 8.86983 37.7722 5.99011 35.5424 3.86688C33.3126 1.74365 30.2883 0.550833 27.1348 0.550833C24.6508 0.543653 22.2287 1.28843 20.2183 2.67761C21.605 3.81721 22.7163 5.22907 23.476 6.81625C24.2356 8.40342 24.6255 10.1283 24.6189 11.8725Z'
            fill='#F79E1B'
          />
          <path
            d='M11.915 23.1943C14.3995 23.2042 16.8225 22.4592 18.8316 21.0675C17.4426 19.9282 16.3281 18.517 15.5641 16.9302C14.8001 15.3433 14.4046 13.6182 14.4046 11.8726C14.4046 10.1269 14.8001 8.40184 15.5641 6.81501C16.3281 5.22817 17.4426 3.81696 18.8316 2.67765C16.8225 1.286 14.3995 0.540953 11.915 0.550879C8.76158 0.550879 5.73728 1.7437 3.50744 3.86693C1.27761 5.99016 0.0249023 8.86988 0.0249023 11.8726C0.0249023 14.8753 1.27761 17.755 3.50744 19.8782C5.73728 22.0015 8.76158 23.1943 11.915 23.1943Z'
            fill='#EB001B'
          />
          <path
            d='M19.6869 20.4509C21.0326 19.3767 22.1052 18.0262 22.8243 16.5008C23.5433 14.9754 23.8901 13.3147 23.8385 11.6433C23.8385 6.83636 19.9858 3.56319 19.5043 3.17578C19.0144 3.56319 15.1617 6.83636 15.1617 11.6433C15.1374 13.3465 15.5343 15.0313 16.3205 16.5613C17.1066 18.0914 18.2598 19.4238 19.6869 20.4509Z'
            fill='#FF5F00'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2507_307810'>
          <rect width='39' height='29' fill='white' transform='translate(0 0.550781)' />
        </clipPath>
        <clipPath id='clip1_2507_307810'>
          <rect width='39' height='29' fill='white' transform='translate(0 0.550781)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MasterCardIcon;
