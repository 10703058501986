import { useTranslation } from "react-i18next";
import MessageInfo from "../webshop/MessagInfo";

const InvoiceInput = () => {
  const { t } = useTranslation();
  return (
    <div id='payment-form' className=' font-gellix text-xs'>
      <MessageInfo
        title={t(
          "Using this payment method will transmit your data to our payment provider for a creditscore check. Checkout will only be completed if the check is successful.",
        )}
      />
      <div id='example-paylater-invoice'></div>
      <div id='error-holder' className='field text-[#9f3a38]'></div>
    </div>
  );
};

export default InvoiceInput;
