import React from "react";

const FlashIcon = () => {
  return (
    <svg
      className='w-[12px] h-[15px]'
      viewBox='0 0 12 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.08326 0.667969C7.61493 0.677969 7.22576 0.850469 6.93409 1.12714L0.686594 7.03964C0.304928 7.40214 0.115761 7.92213 0.178261 8.44463C0.238261 8.9663 0.542427 9.42964 0.996594 9.69297L3.89826 11.3771L2.37659 15.028C2.07493 15.7538 2.32159 16.5938 2.96993 17.0413C3.25659 17.238 3.58576 17.3363 3.91576 17.3363C4.33076 17.3363 4.74326 17.1813 5.06159 16.8796L11.3116 10.9605C11.6933 10.5988 11.8824 10.0788 11.8199 9.5563C11.7591 9.0338 11.4549 8.57047 11.0016 8.30797L8.09993 6.6263L9.59076 3.0488C9.69326 2.83214 9.75076 2.58964 9.75076 2.33464C9.75076 1.4163 9.00909 0.671302 8.08326 0.667969Z'
        fill='#CE992E'
      />
    </svg>
  );
};

export default FlashIcon;
