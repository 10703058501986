/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useCurrencyFormatter from "./useCurrencyFormatter";
import { Maybe, Unit } from "../api/generated";
import { useStatusTranslations } from "./useStatusTranslations";

export interface useQuantityProps {
  quantity: number;
  unitValue: number;
}

export interface useQuantityUnitValueProps {
  unit?: Unit;
  unitValue: number;
  startingPrice?: number | Maybe<number> | undefined;
  basePrice?: number | Maybe<number> | undefined;
}

const useQuantity = () => {
  return useCallback(({ quantity, unitValue }: useQuantityProps) => {
    return quantity % unitValue === 0 ? Math.floor((quantity ?? 0) / unitValue) : 0;
  }, []);
};
export default useQuantity;

export const useUnitValueQuantity = () => {
  return useCallback(({ quantity, unitValue }: useQuantityProps) => {
    return Number(quantity) * Number(unitValue);
  }, []);
};

interface QuantityUnitValueOptions {
  hideFrom?: boolean;
}

export const useQuantityUnitValue = () => {
  const { t } = useTranslation();
  const { productUnitTranslation } = useStatusTranslations();

  const numberFormat = useCurrencyFormatter();

  return useCallback(
    (
      { unitValue, startingPrice, basePrice, unit }: useQuantityUnitValueProps,
      options: QuantityUnitValueOptions = {
        hideFrom: false,
      },
    ) => {
      const basePriceValue = Number(basePrice ?? 0) / 100;
      const price = numberFormat(Number(startingPrice ?? 0) / 100, true);
      const unitAndValue = `${unitValue}${productUnitTranslation(unit!)}`;

      if (basePriceValue <= 0 && !options.hideFrom) {
        return t(`ab {{price}} /{{unitAndValue}}`, {
          price,
          unitAndValue,
        })!;
      }

      if (basePriceValue <= 0) {
        return t(`{{price}} /{{unitAndValue}}`, {
          price,
          unitAndValue,
        })!;
      }

      if (options.hideFrom) {
        return t(`{{basePrice}} + {{price}} /{{unitAndValue}}`, {
          price,
          basePrice: numberFormat(Number(basePrice ?? 0)),
          unitAndValue,
        })!;
      }

      return t(`{{basePrice}} + ab {{price}} /{{unitAndValue}}`, {
        price,
        basePrice: numberFormat(Number(basePrice ?? 0)),
        unitAndValue,
      })!;
    },
    [],
  );
};
