import { Address } from "../api/generated";

const useAddress = ({ address }: { address: Address | undefined }) => {
  if (!address) return "";

  const shippingAddress = { ...address };
  const addressFormat = `${shippingAddress?.street ?? ""} ${shippingAddress?.houseNumber ?? ""}, ${
    shippingAddress?.zip ?? ""
  } ${shippingAddress?.city ?? ""}, ${shippingAddress?.country ?? ""}`;

  return addressFormat;
};

export const getAvailableAddress = ({
  shippingAddress,
  billingAddress,
}: {
  shippingAddress?: Address;
  billingAddress?: Address;
}) => {
  return shippingAddress || billingAddress;
};

export default useAddress;
