import { useTranslation } from "react-i18next";

export enum BackendErrorType {
  ERROR_OFFER_NOT_FOUND = "ERROR_OFFER_NOT_FOUND",
  ERROR_NOT_FOUND = "ERROR_NOT_FOUND",
  ERROR_INVALID_DELIVERY_OPTION = "ERROR_INVALID_DELIVERY_OPTION",
  ERROR_INVALID_CHECKOUT_STATUS = "ERROR_INVALID_CHECKOUT_STATUS",
  ERROR_INVALID_CHECKOUT_PRODUCTS_FROM_NCM = "ERROR_INVALID_CHECKOUT_PRODUCTS_FROM_NCM",
  ERROR_WRONG_OLD_PASSWORD = "ERROR_WRONG_OLD_PASSWORD",
  ERROR_USER_ALREADY_EXISTS = "ERROR_USER_ALREADY_EXISTS",
  ERROR_INVALID_CREDENTIALS = "ERROR_INVALID_CREDENTIALS",
  ERROR_MANUAL_ORDER_IS_NOT_READY = "ERROR_MANUAL_ORDER_IS_NOT_READY",
  ERROR_HUBSPOT_RATE_LIMIT = "ERROR_HUBSPOT_RATE_LIMIT",
  ERROR_INVALID_FIELD = "ERROR_INVALID_FIELD",
  ERROR_META_FIELD_NOT_FOUND = "ERROR_META_FIELD_NOT_FOUND",
  ERROR_INVALID_META_FIELD = "ERROR_INVALID_META_FIELD",
  ERROR_NCM_MISSING_VALUES = "ERROR_NCM_MISSING_VALUES",
  ERROR_NCM_INVALID_STATUS = "ERROR_NCM_INVALID_STATUS",
  ERROR_NCM_INVALID_AUTHENTICATION = "ERROR_NCM_INVALID_AUTHENTICATION",
  ERROR_PAYMENT_PROVIDER = "ERROR_PAYMENT_PROVIDER",
  ERROR_INVALID_PHARMACY_CREDENTIALS = "ERROR_INVALID_PHARMACY_CREDENTIALS",
  ERROR_FEATURE_FLAG_DISABLED = "ERROR_FEATURE_FLAG_DISABLED",
  ERROR_INVALID_ID = "ERROR_INVALID_ID",
  ERROR_NCM_INCOMPLETE = "ERROR_NCM_INCOMPLETE",
  ERROR_UNSUPPORTED_FILE_TYPE = "ERROR_UNSUPPORTED_FILE_TYPE",
  ERROR_VALIDATION = "ERROR_VALIDATION",
  ERROR_INVALID = "ERROR_INVALID",
  ERROR_UNAUTHORIZED = "ERROR_UNAUTHORIZED",
}

const useBackendError = () => {
  const { t } = useTranslation();
  const errors = [
    { type: BackendErrorType.ERROR_OFFER_NOT_FOUND, description: t("Offer not found") },
    { type: BackendErrorType.ERROR_NOT_FOUND, description: t("This does not exist") },
    { type: BackendErrorType.ERROR_UNAUTHORIZED, description: t("Unauthorized") },
    {
      type: BackendErrorType.ERROR_INVALID_DELIVERY_OPTION,
      description: t("Invalid delivery option"),
    },
    {
      type: BackendErrorType.ERROR_INVALID_CHECKOUT_STATUS,
      description: t("Invalid checkout status"),
    },
    {
      type: BackendErrorType.ERROR_INVALID_CHECKOUT_PRODUCTS_FROM_NCM,
      description: t("Invalid checkout products from NCM"),
    },
    { type: BackendErrorType.ERROR_WRONG_OLD_PASSWORD, description: t("Wrong old password") },
    { type: BackendErrorType.ERROR_USER_ALREADY_EXISTS, description: t("User already exists") },
    { type: BackendErrorType.ERROR_INVALID_CREDENTIALS, description: t("Invalid credentials") },
    { type: BackendErrorType.ERROR_INVALID, description: t("Something went wrong") },
    {
      type: BackendErrorType.ERROR_MANUAL_ORDER_IS_NOT_READY,
      description: t("Manual order is not ready"),
    },
    {
      type: BackendErrorType.ERROR_HUBSPOT_RATE_LIMIT,
      description: t("Hubspot rate limit exceed"),
    },
    { type: BackendErrorType.ERROR_INVALID_FIELD, description: t("Invalid field") },
    { type: BackendErrorType.ERROR_META_FIELD_NOT_FOUND, description: t("Meta field not found") },
    { type: BackendErrorType.ERROR_INVALID_META_FIELD, description: t("Invalid meta field") },
    { type: BackendErrorType.ERROR_NCM_MISSING_VALUES, description: t("NCM missing values") },
    { type: BackendErrorType.ERROR_NCM_INVALID_STATUS, description: t("NCM invalid status") },
    {
      type: BackendErrorType.ERROR_NCM_INVALID_AUTHENTICATION,
      description: t("NCM invalid authentication"),
    },
    { type: BackendErrorType.ERROR_PAYMENT_PROVIDER, description: t("Payment provider is wrong") },
    {
      type: BackendErrorType.ERROR_INVALID_PHARMACY_CREDENTIALS,
      description: t("Invalid pharmacy credentials"),
    },
    {
      type: BackendErrorType.ERROR_FEATURE_FLAG_DISABLED,
      description: t("Feature flag is disabled"),
    },
    { type: BackendErrorType.ERROR_INVALID_ID, description: t("Invalid ID") },
    { type: BackendErrorType.ERROR_NCM_INCOMPLETE, description: t("NCM is incomplete") },
    { type: BackendErrorType.ERROR_UNSUPPORTED_FILE_TYPE, description: t("Unsupported file type") },
    { type: BackendErrorType.ERROR_VALIDATION, description: t("Something went wrong") },
  ];

  return errors;
};

export default useBackendError;
