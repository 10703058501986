const MessageIcon2 = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.00342 5.88355L10 9.88186L17.9968 5.8835C17.9364 4.83315 17.0656 4 16.0001 4H4.00009C2.93461 4 2.06372 4.83318 2.00342 5.88355Z'
        fill='#919C8B'
      />
      <path
        d='M18 8.11719L9.99995 12.1172L2 8.11725V13.9993C2 15.1039 2.89543 15.9993 4 15.9993H16C17.1046 15.9993 18 15.1039 18 13.9993V8.11719Z'
        fill='#919C8B'
      />
    </svg>
  );
};

export default MessageIcon2;
